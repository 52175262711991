import React from 'react';
import './Headbar.scss';

interface Props{
  message?: string
}

const Headbar = (props: Props) => {
  return props.message ? (
    <div className="ev-headbar">
      <p dangerouslySetInnerHTML={{__html: props.message}}></p>
    </div>
  ) : null;
};

export default Headbar;