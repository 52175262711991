import React, { Suspense, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";

import Spinner from "./components/Shared/Spinner";
import NotFound from "./components/NotFound/Hero";
import Headbar from "./components/Shared/Headbar";
import APIService from "./services/axios";

const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const Flagships = React.lazy(() => import("./pages/Flagships"));
const Initiatives = React.lazy(() => import("./pages/Orchestrate"));
const Sponsors = React.lazy(() => import("./pages/Sponsors"));

const Events = React.lazy(() => import("./pages/Events"));
const Team = React.lazy(() => import("./pages/Team"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const Index = React.lazy(() => import("./pages/Index"));
const Highlights = React.lazy(() => import("./pages/Highlights"));

const App = () => {
  const [message, setMessage] = useState();
  useEffect(() => {
    APIService.get(`/headbar`)
      .then((res) => {
        setMessage(res.data.message || undefined);
      })
      .catch((err) => {
        console.log(err.response);
      });
    // eslint-disable-next-line
  }, [APIService]);
  return (
    <>
      <Headbar message={message} />
      <Switch>
        <Route
          exact
          path="/about-us"
          render={() => (
            <Suspense fallback={<Spinner />}>
              <AboutUs />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/flagships"
          render={() => (
            <Suspense fallback={<Spinner />}>
              <Flagships />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/highlights"
          render={() => (
            <Suspense fallback={<Spinner />}>
              <Highlights />
            </Suspense>
          )}
        />

        <Route
          exact
          path="/contact-us"
          render={() => (
            <Suspense fallback={<Spinner />}>
              <ContactUs />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/orchestrate"
          render={() => (
            <Suspense fallback={<Spinner />}>
              <Initiatives />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/sponsors"
          render={() => (
            <Suspense fallback={<Spinner />}>
              <Sponsors />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/highlights"
          render={() => (
            <Suspense fallback={<Spinner />}>
              <Highlights />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/events"
          render={() => (
            <Suspense fallback={<Spinner />}>
              <Events />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/t-summit"
          render={() => {
            //@ts-ignore
            window.location = "https://tsummit.aaruush.org/";
            return <Spinner />;
          }}
        />
        <Route
          exact
          path="/campus-ambassador"
          render={() => {
            //@ts-ignore
            window.location = "https://cap.aaruush.org/";
            return <Spinner />;
          }}
        />
        <Route
          exact
          path="/team"
          render={() => (
            <Suspense fallback={<Spinner />}>
              <Team />
            </Suspense>
          )}
        />

        <Route
          exact
          path="/blogs"
          render={() => {
            //@ts-ignore
            window.location = "https://aaruushblog.wordpress.com/";
            return <Spinner />;
          }}
        />
        <Route
          exact
          path="/team-envision"
          render={() => {
            //@ts-ignore
            window.location = "https://envision.aaruush.org/";
            return <Spinner />;
          }}
        />
        <Route
          exact
          path="/register"
          render={() => {
            //@ts-ignore
            window.location = "";
            return <Spinner />;
          }}
        />
        <Route
          exact
          path="/links"
          render={() => {
            //@ts-ignore
            window.location = "https://links.aaruush.org/";
            return <Spinner />;
          }}
        />
        <Route
          exact
          path="/"
          render={() => (
            <Suspense fallback={<Spinner />}>
              <Index />
            </Suspense>
          )}
        />
        <Route path="/*" component={NotFound} />
      </Switch>
    </>
  );
};

export default App;
